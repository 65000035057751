import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import '../styles/imageAccordion.css';

const ImageAccordion = () => {
  const [dataindicators, setDataindicators] = useState([]);
  const [activeIndex, setActiveIndex] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        let response = await fetch('/api/DataIndicators');
        let data = await response.json();
        console.log('DATA:', data);

        setDataindicators(data);
      } catch (error) {
        console.error('Error fetching dataindicators:', error);
      }
    };

    fetchData();
  }, []);

  const handleTouch = (index) => {
    setActiveIndex(index === activeIndex ? null : index);
  };

  const getDynamicStyles = (index) => {
    const imageUrl = dataindicators[index]?.image_url;
    return {
      backgroundImage: `url(${imageUrl})`,
    };
  };

  return (
    <div className="bodyy">
      <div className="containerr">
        {dataindicators.map((DataIndicator, index) => (
          <div
            key={index}
            className={`single ${activeIndex === index ? 'active' : ''}`}
            style={getDynamicStyles(index)}
            onClick={() => handleTouch(index)}
            onTouchStart={() => handleTouch(index)}
          >
            <div className="content justify-center">
              <h2>{DataIndicator.Title}</h2>
              <p className="text-justify">{DataIndicator.Description}</p>
              <Link to={`/explore/Data-Indicator/${DataIndicator.Title}`}>
                <button className="bg-[#173e26] text-white p-2 rounded w-26 hover:bg-[#112e1c]">
                  Know More
                </button>
              </Link>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ImageAccordion;
