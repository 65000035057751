import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
const ArticlePage = () => {
  const { id } = useParams();
    const [article, setArticle] = useState(null);
    const [suggestions, setSuggestions] = useState([]);
    const handleDownload = (downloadLink) => {
      const link = document.createElement('a');
      link.href = downloadLink;
      link.target = '_blank'; // Open in a new tab
      link.click();
    };
   
  
    useEffect(() => {
      // Fetch the article data using the ID
      const fetchArticle = async () => {
        try {
          const response = await fetch(`/overview/article/${id}/`);
          const data = await response.json();
          setArticle(data);
        } catch (error) {
          console.error('Error fetching article:', error);
        }
      };
  
  
      const fetchSuggestions = async () => {
          try {
              const response = await fetch(`/overview/next-articles/${id}/`);
              if (!response.ok) {
                  throw new Error('Failed to fetch suggestions');
              }
              const data = await response.json();
              setSuggestions(data);
          } catch (error) {
              console.error('Error fetching suggestions:', error);
          }
      };
  
      fetchArticle();
      fetchSuggestions();
  }, [id]);


  if (!article ) {
    return <div>Loading...</div>;
} const handleSuggestionClick = async (suggestionId) => {
  try {
      const response = await fetch(`/overview/art/${suggestionId}/`);
      if (!response.ok) {
          throw new Error('Failed to fetch suggestion article');
      }
      const data = await response.json();
      setArticle(data);
  } catch (error) {
      console.error('Error fetching suggestion article:', error);
  }
};

  return (
    <div className="max-w-4xl mx-auto p-4">

      <img src={article.image} alt="Article" className="w-full h-96 rounded-lg object-cover" />
      <h1 className="mt-6 text-3xl font-bold text-gray-800">{article.title}</h1>
      <p className="mt-2 text-sm text-gray-500">{new Date(article.created).toLocaleDateString('en-US', {
    month: 'long',
    day: 'numeric',
    year: 'numeric',
  })}</p>
      
      <p className="mt-4 text-gray-700 text-base whitespace-pre-line text-justify">
  {article.content}
</p>

<button
  className="bg-[#173e26] text-white p-2 rounded hover:bg-[#112e1c] mt-4"
  onClick={() => handleDownload(article.file_url.downloadLink)}
>
  Download Pdf
</button>
      
      {/* Enhanced Suggestions Section */}
      {suggestions.length > 0 && (
      <div className="mt-10">
        <h2 className="text-2xl font-semibold text-gray-800">You May Also Like</h2>
        <div className="mt-4 grid md:grid-cols-2 lg:grid-cols-3 gap-4">
          {suggestions.map((suggestion, index) => (
            <Link to={`/Teasers/${suggestion.id}`} key={suggestion.id} className="block rounded-lg overflow-hidden shadow-lg hover:shadow-2xl transition-shadow duration-200 ease-in-out">
              <img src={suggestion.image} alt="" className="w-full h-48 object-cover"/>
              <div className="p-4 bg-white">
                <h3 className="text-lg font-semibold text-gray-800">{suggestion.title}</h3>
                <div className="text-sm text-gray-600 text-base whitespace-pre-line ">
                <p className="mb-4 multiline-ellipsis">{suggestion.content}</p>
                </div>
                <style jsx>
{`
    .multiline-ellipsis {
        display: -webkit-box;
        -webkit-line-clamp: 3; /* Change the number 4 to the number of lines you want to show */
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
    }
`}
</style>
              </div>
            </Link>
          ))}
        </div>
      </div>
      )}
    </div>
  );
};

export default ArticlePage;
