import React from 'react';
import '../styles/ScrollButton.css'; // Import CSS file for styling

class ScrollButton extends React.Component {
  constructor() {
    super();

    this.state = {
      isBouncing: false
    };
  }

  handleScroll = () => {
    const scrollY = window.scrollY;
    const isBouncing = scrollY > 100; // Change 100 to the desired scroll distance for button to start bouncing
    this.setState({ isBouncing });
  };

  scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    this.setState({ isBouncing: false }); // Stop bouncing when button is clicked
  };

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  render() {
    const { isBouncing } = this.state;

    return (
      <>
        {isBouncing && (
          <button
            title="Back to top"
            className="scroll-button bounce"
            onClick={this.scrollToTop}
          >
            <span className="arrow-up"></span>
          </button>
        )}
      </>
    );
  }
}

export default ScrollButton;
