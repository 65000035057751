import React, {useState, useEffect } from 'react'
import { Link } from 'react-router-dom'


const DataIndicator = () => {
  const [sector, setSector] = useState([]);
  useEffect(() => {
    const fetchsector= async () => {
        try {
          let response = await fetch('/overview/sector');
          let data = await response.json();
          console.log('DATA:', data);
  
          // Log image URLs after setting sliders
          console.log('Image URLs:', data.map(sector => sector.image));
  
          setSector(data);
        } catch (error) {
          console.error('Error fetching overviews:', error);
        }
      };
    

    fetchsector();
   
  }, []);
  return (
    <div className="min-h-[70vh] flex flex-col justify-center items-center md:mx-32">
    {sector.map((sector, index) => (
    <div className='text-center'>
    <h2 className='text-5xl font-semibold leading-tight'>{sector.Title}</h2>
    <p className='mt-5 text-justify self-center text-lg'>{sector.Body}</p>

    <div className="flex flex-col md:flex-row justify-center gap-8 mb-8 items-center my-8">

            <div className="w-72 p-2 bg-white rounded-xl transform transition-all hover:-translate-y-2 duration-300 shadow-lg hover:shadow-2xl">
              <img className="h-40 w-full object-cover rounded-md" src="https://images.unsplash.com/photo-1647462659318-1e9702f92b5b?q=80&w=1931&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" alt=""/>
              <div className="p-2">
                <div className="flex items-center justify-between">
                  <h2 className="font-bold text-lg">Economy</h2>
                  <Link to="/explore/data-indicator/Economy" className="text-white bg-[#173e26] px-3 py-1 rounded hover:bg-[#112e1c]">Know More</Link>
                </div>
              </div>
            </div>
            <div className="w-72 p-2 bg-white rounded-xl transform transition-all hover:-translate-y-2 duration-300 shadow-lg hover:shadow-2xl">
              <img className="h-40 w-full object-cover rounded-md" src="https://images.unsplash.com/photo-1494412685616-a5d310fbb07d?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" alt=""/>
              <div className="p-2">
                <div className="flex items-center justify-between">
                  <h2 className="font-bold text-lg">Trade</h2>
                  <Link to="/explore/data-indicator/Trade" className="text-white bg-[#173e26] px-3 py-1 rounded hover:bg-[#112e1c]">Know More</Link>
                </div>
              </div>
            </div>
            <div className="w-72 p-2 bg-white rounded-xl transform transition-all hover:-translate-y-2 duration-300 shadow-lg hover:shadow-2xl">
              <img className="h-40 w-full object-cover rounded-md" src="https://images.unsplash.com/photo-1524995997946-a1c2e315a42f?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" alt=""/>
              <div className="p-2">
                <div className="flex items-center justify-between">
                  <h2 className="font-bold text-lg">Social</h2>
                  <Link to="/explore/data-indicator/Social" className="text-white bg-[#173e26] px-3 py-1 rounded hover:bg-[#112e1c]">Know More</Link>
                </div>
              </div>
            </div>
          </div>

          <div className="flex flex-col md:flex-row justify-center gap-8 mb-8 items-center my-8">
            <div className="w-72 p-2 bg-white rounded-xl transform transition-all hover:-translate-y-2 duration-300 shadow-lg hover:shadow-2xl">
              <img className="h-40 w-full object-cover rounded-md" src="https://images.unsplash.com/photo-1611974789855-9c2a0a7236a3?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" alt=""/>
              <div className="p-2">
                <div className="flex items-center justify-between">
                  <h2 className="font-bold text-lg">Investment</h2>
                  <Link to="/explore/data-indicator/Investment" className="text-white bg-[#173e26] px-3 py-1 rounded hover:bg-[#112e1c]">Know More</Link>
                </div>
              </div>
            </div>
            <div className="w-72 p-2 bg-white rounded-xl transform transition-all hover:-translate-y-2 duration-300 shadow-lg hover:shadow-2xl">
              <img className="h-40 w-full object-cover rounded-md" src="https://images.unsplash.com/photo-1535732759880-bbd5c7265e3f?q=80&w=1964&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" alt=""/>
              <div className="p-2">
                <div className="flex items-center justify-between">
                  <h2 className="font-bold text-lg">Infrastructure</h2>
                  <Link to="/explore/data-indicator/Infrastructure" className="text-white bg-[#173e26] px-3 py-1 rounded hover:bg-[#112e1c]">Know More</Link>
                </div>
              </div>
            </div>
            <div className="w-72 p-2 bg-white rounded-xl transform transition-all hover:-translate-y-2 duration-300 shadow-lg hover:shadow-2xl">
              <img className="h-40 w-full object-cover rounded-md" src="https://images.unsplash.com/photo-1554224154-22dec7ec8818?q=80&w=1887&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" alt=""/>
              <div className="p-2">
                <div className="flex items-center justify-between">
                  <h2 className="font-bold text-lg">Doing Business</h2>
                  <Link to="/explore/data-indicator/Doing-Business" className="text-white bg-[#173e26] px-3 py-1 rounded hover:bg-[#112e1c]">Know More</Link>
                </div>
              </div>
            </div>
          </div>
        </div>
    ))}
    </div>
  )
}

export default DataIndicator