import React, {useState, useEffect } from 'react'
import { Link } from 'react-router-dom'


const RegulatoryandStrategy = () => {
  const [explore, setExplore] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        let response = await fetch('/overview/explore');
        let data = await response.json();
        console.log('DATA:', data);

        // Log image URLs after setting sliders
        console.log('Image URLs:', data.map(explore => explore.image));

        setExplore(data);
      } catch (error) {
        console.error('Error fetching overviews:', error);
      }
    };
    

    fetchData();
   
  }, []);
  return (
    <div>
    {explore.map((explore, index) => (
    <div className='min-h-[70vh] flex flex-col md:flex-row justify-center place-items-center center items-center  gap-10 md:mx-32 '>
            <div key={index} className='md:w-2/4 text-center'>
                <h2 className='text-5xl font-semibold leading-tight'>{explore.Title}</h2>

                <p className='mt-5 text-justify self-center text-lg'>{explore.Body}
                </p>

                <Link to='/explore/National-strategies-and-policies'><button className="bg-[#173e26] text-white p-2 rounded w-fit hover:bg-[#112e1c] mt-10"> Know More</button></Link>
            </div>
            <div className='w-full md:w-2/4'>
            <img src={explore.svg_file} alt={`SVG ${index}`} />
            </div>

            
    </div>
    ))}
    </div>
  )
}

export default RegulatoryandStrategy