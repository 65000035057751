import React, { useState, useEffect } from 'react'
//import pic from '../images/Business Plan-amico.png'
//import pic2 from '../images/Statistics-bro.png'
//import pic3 from '../images/Visual data-amico.png'
//import pic4 from '../images/Analysis-amico.png'
import { Link } from 'react-router-dom'



const Explorecomp = () => {
    const [explore, setExplore] = useState([]);
    const [Dataind, setDataInd] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        let response = await fetch('/overview/explore');
        let data = await response.json();
        console.log('DATA:', data);

        // Log image URLs after setting sliders
        console.log('Image URLs:', data.map(explore => explore.image));

        setExplore(data);
      } catch (error) {
        console.error('Error fetching overviews:', error);
      }
    };

    const fetchdataind = async () => {
      try {
        let response = await fetch('/overview/DataInd');
        let data = await response.json();
        console.log('DATA:', data);

        

        setDataInd(data);
      } catch (error) {
        console.error('Error fetching overviews:', error);
      }
    };
    fetchData();
    fetchdataind ();
  }, []);
  const [isImageLeft, setIsImageLeft] = useState(true);

const toggleImagePosition = () => {
  setIsImageLeft(prevState => !prevState);
};
return (
  <div className='justify-center ml-4 mr-4'>
      
      {explore.map((explore, index) => (
<div key={index} className='min-h-[70vh] flex flex-col md:flex-row justify-center place-items-center center items-center gap-6 md:mx-32'>
  <div className='md:w-2/4 text-center'>
    <h2 className='text-5xl font-semibold leading-tight'>{explore.Title}</h2>
    <p className='mt-5 text-justify self-center text-lg'>{explore.Body}</p>
    <Link to='/explore/Regulatory-and-Strategy'>
      <button className="bg-[#173e26] text-white p-2 rounded w-fit hover:bg-[#112e1c] mt-10"> Know More</button>
    </Link>
  </div>
  <div className={`w-full md:w-2/4 ${index % 2 === 0 ? 'md:order-first' : 'md:order-last'}`}>
    <img src={explore.svg_file} alt={`SVG ${index}`} />
  </div>
</div>
))}


  {Dataind.map((item, index) => (
    <div key={index} className={`min-h-[70vh] flex flex-col md:flex-row justify-center place-items-center center items-center gap-6 md:mx-32 ${index % 2 === 0 ? 'flex-row' : 'flex-row-reverse'}`}>
    <div className={`w-full md:w-2/4 ${index % 2 === 0 ? 'md:order-last' : 'md:order-first'}`}>
      <img src={item.Image} alt='' />
    </div>
    <div className='w-full md:w-2/4 text-center'>
      <h2 className='text-5xl font-semibold leading-tight'>{item.Title}</h2>
      <p className='mt-5 text-justify self-center text-lg'>{item.Body}</p>
      <Link to={`/explore/${item.Title}`}>
        <button className='bg-[#173e26] text-white p-2 rounded w-fit hover:bg-[#112e1c] mt-10 my-8'> Know More</button>
      </Link>
    </div>
  </div>
  ))}





  </div>
)
}

export default Explorecomp

