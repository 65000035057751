import React, { useState, useEffect } from 'react';
import SearchBar from '../components/Searchbar';
import { Card, CardHeader, CardBody, CardFooter, Typography, Button } from "@material-tailwind/react";
import { Link } from "react-router-dom";
import { motion } from 'framer-motion';

const Article = () => {
    const [articles, setArticles] = useState([]);
    const [hoveredArticle, setHoveredArticle] = useState(null);

    useEffect(() => {
        const fetchArticles = async () => {
            try {
                const response = await fetch('overview/articles/');
                if (!response.ok) {
                    throw new Error('Failed to fetch articles');
                }
                const data = await response.json();
                setArticles(data);
            } catch (error) {
                console.error('Error fetching articles:', error);
            }
        };

        fetchArticles();
    }, []);

    function truncateContent(content) {
        const words = content.split(' ');
        if (words.length > 10) {
            return words.slice(0, 10).join(' ') + '...';
        }
        return content;
    }

    const handleMouseEnter = (id) => {
        setHoveredArticle(id);
    };

    const handleMouseLeave = () => {
        setHoveredArticle(null);
    };

    return (
        <>
            <SearchBar />
            <div className='flex flex-wrap gap-6 mb-6 justify-center'>
            {articles
    .slice() // Create a shallow copy to avoid mutating the original array
    .sort((a, b) => new Date(b.created) - new Date(a.created)) // Sort articles by date descending
    .map((article, index) => (
        <motion.div
            key={article.id}
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: index * 0.1, duration: 0.8 }}
            className='wst max-md:w-full'
        >
            <Card
                className="mt-12 md:w-96"
                style={{
                    transition: 'box-shadow 300ms ease-in-out',
                    boxShadow: hoveredArticle === article.id ? '0 10px 15px rgba(0, 0, 0, 0.2)' : 'none',
                }}
                onMouseEnter={() => handleMouseEnter(article.id)}
                onMouseLeave={handleMouseLeave}
            >
                <CardHeader color="blue-gray" className="relative h-56 -mt-6 overflow-hidden">
                    <img
                        src={article.image}
                        alt="Article"
                        style={{
                            transition: 'transform 300ms ease-in-out',
                            transform: hoveredArticle === article.id ? 'scale(1.1)' : 'scale(1.0)',
                            width: '100%',
                            height: '100%',
                            objectFit: 'cover',
                        }}
                    />
                </CardHeader>
                <CardBody>
                    <Typography
                        variant="h5"
                        color="blue-gray"
                        className="mb-2"
                        style={{
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            maxWidth: '100%',
                            fontSize: '1.5rem',
                            fontWeight: 'bold',
                        }}
                    >
                        {article.title}
                    </Typography>
                    <Typography color="blue-gray" className="mb-2">
                        {new Date(article.created).toLocaleDateString('en-US', {
                            month: 'long',
                            day: 'numeric',
                            year: 'numeric',
                        })}
                    </Typography>
                    <Typography>
                        {truncateContent(article.content)}
                    </Typography>
                </CardBody>
                <CardFooter className="pt-0">
                    <Link to={`/Teasers/${article.id}`}>
                        <Button className="bg-[#173e26]">Read More</Button>
                    </Link>
                </CardFooter>
            </Card>
        </motion.div>
    ))}
</div>

        </>
    );
};

export default Article;
