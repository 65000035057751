import React, { useState, useEffect } from 'react';
import '../styles/search.css';


const SearchBar = () => {
  const [isFilterVisible, setIsFilterVisible] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  //const [queryString, setQueryString] = useState('');
  const [data, setData] = useState([]);
  const [region, setRegion] = useState('');
  const [year, setYear] = useState('');
  const [filters, setFilters] = useState({
    sector: [],
    region: [],
    start_year: [],
    end_year: [],
  });

  useEffect(() => {
    handleSearch();
  }, []);

  const handleSearch = async () => {
   
    try {
      const response = await fetch('/overview/get-filter-options/');
      if (response.ok) {
        const data = await response.json();
        console.log('Filter options:', data);
        setData(data);
      } else {
        throw new Error('Failed to fetch filter options');
      }
    } catch (error) {
      console.error('Error fetching filter options:', error);
      
    } 
  };


  const handlesetsetSearchTermChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const searchCourse = () => {
    if (searchTerm !== '') {
      window.location.hash = '/search/' + searchTerm;
    }
  };

  const handleApplyFilter = (e) => {
    const { name, options } = e.target;
    const selectedValues = Array.from(options)
      .filter(option => option.selected)
      .map(option => option.value);

    setFilters((prevFilters) => ({
      ...prevFilters,
      [name]: selectedValues,
    }));
  };

  const applyFilter = () => {
    const { sector, region, start_year, end_year } = filters;
    let filterString = '';

    if (sector.length) filterString += `sector=${sector.join(',')}&`;
    if (region.length) filterString += `region=${region.join(',')}&`;
    if (start_year.length) filterString += `start_year=${start_year.join(',')}&`;
    if (end_year.length) filterString += `end_year=${end_year.join(',')}&`;

    if (filterString) {
      window.location.hash = `/filter/?${filterString.slice(0, -1)}`; // Remove trailing &
    }
  };
  return (
    <>
      <div className="flex flex-wrap items-center space-x-2 p-4 search justify-center">
        <input
          type="text"
          placeholder="Search..."
          value={searchTerm}
          onChange={handlesetsetSearchTermChange}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              searchCourse();
            }
          }}
          className="border p-2 rounded w-full inputSearch lg:w-[60%]"
        />
        <div className='space-x-2 p-2'>
          <button
            className={`bg-[#173e26] text-white p-2 rounded hover:bg-[#153722] w-24 btn md:hidden ${
              searchTerm.trim() === '' ? 'cursor-not-allowed' : ''
            }`}
            onClick={searchCourse}
            type="button"
            disabled={searchTerm.trim() === ''}
          >
            Search
          </button>
          <button
            className="bg-white text-[#173e26] border-2 border-[#173e26] hover:border-bg-[#153722] p-2 rounded w-24 btn"
            onClick={() => setIsFilterVisible(!isFilterVisible)}
          >
            {isFilterVisible ? 'Cancel' : 'Filter'}
          </button>
        </div>
      </div>
      {isFilterVisible && (
        <div className="w-[70%] mx-auto mt-8">
          <div className="flex flex-col md:flex-row gap-4 mt-4">
            {/* sector Filter */}
            <div className="flex-1">
  <select
    name="sector"
    value={filters.sector}
    onChange={handleApplyFilter}
    className="w-full p-2 border rounded"
    aria-label="Select Sector"
  >
    <option value="">Select Sector</option>
    {Array.from(new Set(data.map(option => option.value)))
      .filter(name => name !== undefined)
      .map((name) => {
        const count = data.filter(option => option.value === name).length;
        return (
          <option key={name} value={name}>
            {count > 1 ? `${name}` : name}
          </option>
        );
      })}
  </select>
</div>

          {/* region Filter */}
          <div className="flex-1">

          <select
            name="region"
            value={filters.region}
            onChange={handleApplyFilter}
            className="w-full p-2 border rounded"
            aria-label="Select Region"
          >
            <option value="">Select Region</option>
          {Array.from(new Set(data.map(option => option.value2))).filter(name => name !== undefined).map((region) => (
            <option key={region} value={region}>
              {region}
            </option>
            ))}
          </select>
          </div>
          {/*start year Filter */}
          <div className="flex-1">

          <select
           name="start_year"
           value={filters.start_year}
 
           onChange={handleApplyFilter}
            className=" w-full p-2 border rounded"
            aria-label="Select Year"
          >
            <option value="">Select Year</option>
            {Array.from(new Set(data.map(option => option.value3))).filter(name => name !== undefined).map((year) => (
            <option key={year} value={year}>
              {year}
            </option>
            ))}
          </select>
          </div>
          {/* end year Filter */}
          <div className="flex-1">

          <select
           name="end_year"
           value={filters.end_year}
 
           onChange={handleApplyFilter}
            className=" w-full p-2 border rounded"
            aria-label="Select Year"
          >
            <option value="">Select Year</option>
            {Array.from(new Set(data.map(option => option.value3))).filter(name => name !== undefined).map((year) => (
            <option key={year} value={year}>
              {year}
            </option>
            ))}
          </select>
          </div>
         {/* Apply Filters Button */}
         <button
        className={`bg-[#173e26] text-white p-2 rounded w-24 hover:bg-[#112e1c] ${
          !filters.sector.length && !filters.region.length && !filters.start_year.length && !filters.end_year.length  ? 'cursor-not-allowed' : ''
        }`}
        onClick={applyFilter}
        type="button"
        disabled={!filters.sector.length && !filters.region.length && !filters.start_year.length && !filters.end_year.length}
      >
        Apply
      </button>
        </div>
        </div>
      )}
    </>
  );
};

export default SearchBar;

