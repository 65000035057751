import React, { useState, useEffect } from 'react';
import Accordion from '../components/Accordion'

 // Assuming you have an Accordion component

const Business = () => {
  const [accordionData, setAccordionData] = useState([]);
  const [categoriesWithCount, setCategoriesWithCount] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [showAllButton, setShowAllButton] = useState(true); // Initially hide the button
  //const [clicked, setClicked] = useState(false);

  const handleCategoryClick = (category) => {
    setSelectedCategory(category === selectedCategory ? null : category);
    setShowAllButton(true);
    if (category === null) {
      fetchData();
    } else {
      fetchData(category);
    }
  };

  const handleShowAllClick = () => {
    setSelectedCategory(null);
    setShowAllButton(false);
    fetchData();
  };

  useEffect(() => {
    fetchCategoriesWithCount();
  }, []);

  useEffect(() => {
    if (categoriesWithCount.length > 0) {
      const firstCategory = categoriesWithCount[0].category;
      setSelectedCategory(firstCategory);
      fetchData(firstCategory);
    }
  }, [categoriesWithCount]);

  const fetchData = async (category) => {
    try {
      let url = '/overview/Framework';
      if (category) {
        url += `?category=${category}`;
      }
      const response = await fetch(url);
      if (response.ok) {
        const data = await response.json();

        // Process accordion data
        const dataByRepository = {};
        data.forEach(item => {
          const repositoryName = item.data_repository_name;

          if (!dataByRepository[repositoryName]) {
            dataByRepository[repositoryName] = [];
          }

          dataByRepository[repositoryName].push({
            title: item.PdfTitle,
            downloadLink: item.file,
            isFree: item.Status === 'Free',
          });
        });

        const newData = Object.keys(dataByRepository).map(repositoryName => ({
          title: repositoryName,
          pdfs: dataByRepository[repositoryName],
        }));

        setAccordionData(newData);
      } else {
        console.error('Failed to fetch accordion data');
      }
    } catch (error) {
      console.error('Error fetching accordion data:', error);
    }
  };

  const fetchCategoriesWithCount = async () => {
    try {
      const response = await fetch('/overview/Category'); // Adjust your API endpoint accordingly
      if (response.ok) {
        const data = await response.json();
        setCategoriesWithCount(data);
      } else {
        console.error('Failed to fetch categories with count');
      }
    } catch (error) {
      console.error('Error fetching categories with count:', error);
     
    }
  };
    

    return (
      <>
      <div className='ml-4 md:ml-12 mb-4 md:mb-10'>
          <h1 className='text-2xl md:text-5xl font-semibold leading-tight text-[#173e26]'>
              Business and Regulatory Framework
          </h1>
      </div>
  
      <div className='flex gap-24'>
      <div className='w-full mb-4 '>
        {accordionData 
  .map((accordion, index) => (
    <Accordion key={index} title={accordion.title} pdfs={accordion.pdfs} isfree={accordion.isfree} />
  ))}
  {showAllButton && (
    <button className="mx-1 font-bold text-[#173e26] hover:underline" onClick={handleShowAllClick}>
      Show All
    </button>
  )}

      </div>
          
          <div className="hidden w-4/12 -mx-8 lg:block">
              <h1 className="mb-4 text-xl font-bold text-[#173e26]">Hierarchy of Laws</h1>
              <div className="flex flex-col max-w-sm px-6 py-4 mx-auto bg-white rounded-lg shadow-md">
                  <ul className="-mx-4">
                      {categoriesWithCount.map((category, index) => (
                        
                          <li key={category.category} className="flex items-center">
                              <p key={index}>
                                  <button className={`mx-1 font-bold ${
                selectedCategory === category.category
                  ? 'text-white bg-green-900'
                  : 'text-[#173e26] hover:underline'
              } rounded-full px-3 py-1 transition-colors duration-300 ease-in-out`}
                                  onClick={() => handleCategoryClick(category.category)}>
                                      {category.category}
                                  </button>
                                  <span className="text-sm font-light text-gray-700">{category.count} Files</span>
                              </p>
                          </li>
                          
                    ))}
                  </ul>
              </div>
          </div>
      </div>
  </>
  
    );

}

export default Business;