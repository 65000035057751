import React, { useState, useEffect } from 'react';
import {Menu,
  MenuHandler,
  Button,
  MenuList,
  MenuItem,
  Input,} from "@material-tailwind/react";

import {
  Rectangle,
  BarChart,
  Bar,
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Brush,
 
} from "recharts";
import { AiOutlineSearch } from 'react-icons/ai';


const Infrastructure = () => {
  // State to keep track of which menu item is clicked
  
  
  const [chartType, setChartType] = useState('line');
  const [data, setData] = useState([]);
  
  // Then define filteredData
  
  // Finally, define selectedRange
  //const filteredData = groupedData[selectedIndicator] || [];
  const [filteredData, setFilteredData] = useState([]);
  const groupedData = data.reduce((acc, item) => {
    if (!acc[item.Indicator_Name]) {
      acc[item.Indicator_Name] = [];
    }
    acc[item.Indicator_Name].push(item);
    return acc;
  }, {});
  const [selectedIndicator, setSelectedIndicator] = useState(Object.keys(groupedData)[0] || ''); // Default to empty string if no keys

  const [selectedRange, setSelectedRange] = useState([0, 0]);
  // Log filteredData and selectedRange
  const [searchTerm, setSearchTerm] = useState('');
  const [maxValue, setMaxValue] = useState(0);
  const [minValue, setMinValue] = useState(0);
  const [menuOpen, setMenuOpen] = useState(false);
 // Function to handle menu item click
 const handleMenuItemClick = (indicatorName) => {
  const newFilteredData = groupedData[indicatorName] || [];
  const max = Math.max(...newFilteredData.map(item => item.Gdp));
  const min = Math.min(...newFilteredData.map(item => item.Gdp));
  setSelectedIndicator(indicatorName);
  setFilteredData(newFilteredData);
  setSelectedRange([0, newFilteredData.length - 1]);
  setMaxValue(max);
  setMinValue(min);
  setMenuOpen(false); // Close the menu when an item is selected
  setSearchTerm(''); // Reset search term
};
useEffect(() => {
  if (filteredData.length > 0) {
    setSelectedRange([0, filteredData.length - 1]);
  }
}, [filteredData]);
// Automatically select the first indicator when the component mounts
useEffect(() => {
  if (Object.keys(groupedData).length > 0 && !selectedIndicator) {
    const firstIndicator = Object.keys(groupedData)[0];
    setSelectedIndicator(firstIndicator);
    setFilteredData(groupedData[firstIndicator]);
    const max = Math.max(...filteredData.map(item => item.Gdp)); // Replace 'Gdp' with the actual property name
    setMaxValue(max);
  }
}, [groupedData, selectedIndicator]);







const handleSearchChange = (e) => {
  setSearchTerm(e.target.value.toLowerCase());
};



const handleClick = (e) => {
  e.stopPropagation();
};

const handleRangeChange = ({ startIndex, endIndex }) => {
  setSelectedRange([startIndex, endIndex]);
};

  const handleLineClick = () => {
    setChartType('line');
  };

  const handleBarClick = () => {
    setChartType('bar');
  };







 
    useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch('/data/infrastructure'); 
        if (response.ok) {// Replace '/api/importantlinks/' with your actual Django API endpoint
        const data = await response.json();
        console.log('exceldata:', data);
        setData(data);
      } else {
        throw new Error('Failed to fetch filter options');
      }
    } catch (error) {
      console.error('Error fetching filter options:', error);
    }
  };

    fetchData();
  }, []);
  const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
      const data = payload[0].payload;
      return (
        <div className="bg-white shadow-md p-2 rounded-md">
          <p><strong className='text-[#173e26]'>Year:</strong> {data.Year}</p>
          <p><strong className='text-[#173e26]'></strong> {data.Gdp}</p>
     
          {/* Add additional information here */}
        </div>
      );
    }
  
    return null;
  };
  






  return (
    <div>
      <div className='flex justify-center items-center'>
      <Menu
          open={menuOpen}
          handler={setMenuOpen}
          dismiss={{
            itemPress: false, // Prevent menu from closing on item press
            overlayClick: true,
          }}
        >
          <MenuHandler>
            <Button className='bg-white flex items-center justify-center w-fit text-black m-4 md:w-2/5'>
              <AiOutlineSearch size={20} className='mr-4'/> Data Indicator
            </Button>
          </MenuHandler>
         

          <MenuList className='m-2'>
    <Input placeholder='Search' className='rounded-sm' onChange={handleSearchChange} onClick={handleClick} value={searchTerm} />
    {Object.keys(groupedData).filter(indicatorName =>
      indicatorName.toLowerCase().includes(searchTerm.toLowerCase())
    ).map((indicatorName, index) => (
      <MenuItem key={index} className='hover:bg-[#173e26] hover:bg-opacity-20 p-2' onClick={() => handleMenuItemClick(indicatorName)}>
        <div>{indicatorName}</div>
        {groupedData[indicatorName].map((item, idx) => (
          <div key={idx}>{item.someOtherProperty}</div>
        ))}
      </MenuItem>
    ))}
  </MenuList>


        </Menu>
      </div>
      
    <div className=' flex justify-center items-center'>
      
      <div className='md:w-4/6 h-[100%] mx-2 my-4 bg-white rounded-md overflow-hidden shadow-md'>
      {selectedIndicator && (
        <>
        <h2 className='text-center font-bold text-[#173e26] mt-4'>{selectedIndicator}</h2>
        <div className="flex justify-center mt-4 mb-12">
          <button className={`mr-4 px-4 py-2 rounded ${chartType === 'line' ? 'bg-gray-200' : ''}`} onClick={handleLineClick}>Line</button>
          <button className={`px-4 py-2 rounded ${chartType === 'bar' ? 'bg-gray-200' : ''}`} onClick={handleBarClick}>Bar</button>
        </div>
        
        <ResponsiveContainer width="100%" height={300}>
          {chartType === 'line' ? (
            <LineChart 
            data={filteredData}
            margin={{
              top: 0,
              right: 45,
              left: 20,
              bottom: 55,
            }}
            >
              <CartesianGrid strokeDasharray="0" horizontal='' vertical='true' />
              <XAxis dataKey="Year" padding={{ left: 30, right: 30 }} angle={-45} textAnchor="end"/>
              <YAxis 
                  domain={[minValue, maxValue]} 
                  tickFormatter={tick => Math.round(tick)} 
                />
              <Tooltip content={CustomTooltip}/>
              <Line type="monotone" dataKey="Gdp" stroke="#173e26" />
              
              <Brush dataKey="Year" height={30} y={260} stroke="#173e26" onChange={handleRangeChange} startIndex={selectedRange[0]} 
          endIndex={selectedRange[1]} />
            </LineChart>
            
          ) : (
            <BarChart 
            data={filteredData}
            margin={{
              top: 0,
              right: 45,
              left: 20,
              bottom: 55,
            }}
            >
              <CartesianGrid strokeDasharray="0" horizontal='true' vertical=''/>
              <XAxis dataKey="Year" padding={{ left: 30, right: 30 }} angle={-45} textAnchor='end'/>
              <YAxis 
                      domain={[minValue, maxValue]} 
                      tickFormatter={tick => Math.round(tick)} 
                    />
              <Tooltip content={CustomTooltip}/>
              
              <Bar dataKey="Gdp" fill="#d9d9d9" activeBar={<Rectangle fill='#173e26'/>}/>
              <Brush dataKey="Year" y={260} height={30} stroke="#173e26" onChange={handleRangeChange} startIndex={selectedRange[0]} endIndex={selectedRange[1]} />
            </BarChart>
          )}
        </ResponsiveContainer>
        
        <div className="bg-white p-4 rounded-md shadow-md flex">
          <span className="font-bold text-[#173e26]">Selected Range:</span> {filteredData[selectedRange[0]]?.Year} - {filteredData[selectedRange[1]]?.Year}
          <p className='ml-auto'>Source: World Bank </p>
        </div>
        </>
      )}
      </div>
    </div>
    </div>
  );
};

export default Infrastructure;

