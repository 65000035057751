import React from 'react'
import Steps from '../components/steps.jsx'

const lnvlifecycle = () => {
  return (
    <div className='h-fit mb-12'>
      <Steps/>
    </div>
  )
}

export default lnvlifecycle