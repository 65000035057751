import React, {useEffect, useState} from 'react'
import {Modal, ModalContent, ModalHeader, ModalBody, ModalFooter, useDisclosure} from "@nextui-org/react";
import {Button} from "@nextui-org/react";


const Preimplementation = () => {
  const [preimps, setPreimps] = useState([]);
  
  const { isOpen, onOpen, onClose } = useDisclosure();
  
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response1 = await fetch('/lifecycle/imp');
        const result1 = await response1.json();
        setPreimps(result1);

      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchData();
  }, []);
  const sendEmail = () => {
    window.open("mailto:info@aceadvisors.org?subject=SendMail&body=Description");
  };

  const handleDownload = (downloadLink) => {
    const link = document.createElement('a');
    link.href = downloadLink;
    link.target = '_blank'; // Open in a new tab
    link.click();
  };

  return (
    <div className='w-full h-full flex flex-col items-center justify-center'>
      {preimps.map((preimp, index) => (
        <div key={index}>
   <div className='flex flex-wrap items-center justify-center'>
    <img src={preimp.svg_file} alt={`SVG ${index}`} className='md:w-[30%] max-sm:mx-4' />
        <img src={preimp.image} alt="" className='md:w-[30%] max-sm:mx-4 m-6' />
      </div>
      <div className='flex flex-wrap items-center justify-center'>
      <div className="w-full md:w-[350px] mx-6 my-4 bg-white rounded-md overflow-hidden shadow-md">
      <div className="p-6">
        <h2 className="text-xl font-semibold mb-2 text-center">{preimp.Title}</h2>
        <p className="text-gray-600 mb-4 text-justify">{preimp.Description}</p>
      </div>
    </div>
    
        <div className="mt-6 mb-6 max-sm:mx-2">
        <div class="max-w-3xl px-10 py-6 mx-auto bg-white rounded-lg shadow-md">
            <div className="flex items-center justify-between">
              <span class="font-light text-gray-600">{new Date(preimp.created).toLocaleDateString('en-US', {
                year: 'numeric',
                month: 'short',
                day: 'numeric',
              })}</span>
            </div>
            <div className="mt-2">
  <h1 className="text-2xl font-bold text-[#173e26] hover:underline">{preimp.PdfTitle}</h1>
  <p className="mt-2 text-black text-justify">{preimp.Body}</p>
</div>

            <div className="flex items-center justify-between mt-4">
            <button className="px-2 py-1 font-bold text-gray-100 bg-[#173e26] rounded hover:bg-[#24542f]"
                                onClick={preimp.pdfs.isFree ? () => handleDownload(preimp.pdfs.downloadLink) : onOpen}>
                                  {preimp.pdfs.isFree ? 'Read More' : 'Read More'}</button>
                                  <Modal isOpen={isOpen} onClose={onClose}>
                                    <ModalContent>
                                      <ModalHeader>Send Email</ModalHeader>
                                      <ModalBody> Interested? Please send us a request through our email info@aceadvisors.org.</ModalBody>
                                      <ModalFooter>
                                        <Button color="danger" variant="light" onPress={onClose}>Close</Button>
                                        <Button color='success' variant='light' onClick={sendEmail}>Send Email</Button>
                                        </ModalFooter>
                                        </ModalContent></Modal>
              <div>
                <button className="flex items-center">
                  <img src={preimp.region_image} alt="avatar" className="object-cover w-10 h-10 mx-4 rounded-full block" />
                  <h1 className="font-bold text-[#173e26] hover:underline">{preimp.region_name}</h1>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
</div>
))}
    </div>
  )
}

export default Preimplementation
