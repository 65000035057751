import React, {useState, useEffect} from 'react';
import { Link } from 'react-router-dom';

const Detail = () => {
  const [result, setResult] = useState({
    reg: [],
  });

  const fetchover = async () => {
    try {
      const response = await fetch('/api/Frontoverview');
      if (response.ok) {
        const data = await response.json();
        setResult({
          reg: data['reg '],
        });
      } else {
        console.error('Failed to fetch data');
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    fetchover();
  }, []);

  

  return (
    <div className="flex flex-wrap justify-center items-center">

      {/* Card 1 */}
      {result.reg && result.reg.map((Regulatory, index) => (
      <div key={index} className="w-full md:w-[350px] h-[600px] mx-2 my-4 bg-white rounded-md overflow-hidden shadow-md">
        <img
          className="w-full h-48 object-cover object-center"
          src={Regulatory.image}
          alt=""
        />
        <div className="p-6">
          <h2 className="text-xl font-semibold mb-2 text-center">{Regulatory.Title}</h2>
          <p className="text-gray-600 mb-4 text-justify">{Regulatory.Description}</p>
          <Link to={`/explore/${Regulatory.Title}`}>
          <button
            className="bg-[#173e26] text-white p-2 rounded w-24 hover:bg-[#112e1c]"
          >
            Read More
          </button>
          </Link>
        </div>
      </div>
))}
      

    </div>
  );
};

export default Detail;
